import React, { useState, useEffect, useRef } from 'react'
import {createGlobalStyle} from 'styled-components'
import styled from 'styled-components'

export const Base = createGlobalStyle`
  body,
  html {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 20px auto;
    background: #DA0052;
    font-weight: 100;
    zoom: 1;
    font-size: 10px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  gap: 1rem;
  width: 38rem;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 50px;
  height: 50px;
  background-image: url('/images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding-left: 19rem;
    font-size: 2.7rem;
    font-weight: bold;
    color: #ffffff;
  }
`

const Title = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 0 3rem 0;
  font-weight: bold;
  color: #ffffff;
  font-size: 3rem;
  align-items: center;
  text-align: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 0 0 2rem 0;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  .main-image {
    width: 300px;
    height: 610px;
    background-image: url('/images/main.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1rem;
    border: .1rem solid #efebef;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    align-items: center;
    justify-content: center;
    user-select: none;
    background-image: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;
    font-weight: normal;
    border: 0;
  }

  button.apple {
    width: 180px;
    height: 70px;
    background-image: url('/images/apple.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -99999999px
  }

  button.google {
    width: 180px;
    height: 70px;
    background-image: url('/images/google.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -99999999px
  }
`

const Explain = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 3rem 0 3rem 0;
  font-weight: bold;
  color: #ffffff;
  font-size: 3rem;
  align-items: center;
  text-align: center;
`

const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 3rem 0 3rem 0;
  font-weight: normal;
  color: #333333;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
`

const Index = () => {

  useEffect(() => {

  }, [])

  return (
    <>
    <Base />
      <Container>
        <Header>
          <Logo><div className="name">기프티나라</div></Logo>
        </Header>
        <Content>
          <Title>세상 모든 기프티콘<br /> 할인! 기프티나라!</Title>
          <div className="main-image"></div>
        </Content>
        <Explain>지금 바로 다운로드 하세요</Explain>
        <ButtonWrap>
          <button className="apple" onClick={() => window.open('https://apps.apple.com/kr/app/%EA%B8%B0%ED%94%84%ED%8B%B0%EB%82%98%EB%9D%BC/id6447502221')}>애플</button>
          <button className="google" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.giftinara&pcampaignid=web_share')}>구글</button>
        </ButtonWrap>

        <Footer>
          대표 : 송민후 | 이메일 : giftinara1@gmail.com<br/>
          사업자 등록번호 : 825-81-02779<br/>
          통신판매업신고번호 : 제2023-별내-0449호<br/>
          <br/>
          <br/>
          경기도 남양주시 별내중앙로 26 10층
        </Footer>
      </Container>
    </>
  )
}

export default Index
